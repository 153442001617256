import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { getDetailById, confirmComplete } from '@api/maintenance';
import { getCost } from '@api/clean';
import { getStorage } from '@utils/storage.js';
import { mapGetters } from 'vuex';
import { ImagePreview } from 'vant';
import { isIOS } from '@utils/utils';
export default {
  name: 'CleanDetail',
  mixins: [common],
  data() {
    return {
      headerTitle: '工单详情',
      userInfo: JSON.parse(getStorage('user_info', 'localStorage')),
      // 空间一级分类
      // 层级
      form: {
        spaceName: null,
        spaceId: null,
        questionDes: null,
        questionCategoryId: null,
        questionCategoryName: null,
        expectRepairDate: null,
        expectRepairTimeSpan: null,
        contactsName: null,
        contactsMobile: null,
        contactsRemark: null,
        questionDesImgList: []
      },
      useBack: false
    };
  },
  props: {},
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  created() {
    const {
      token,
      projectId,
      userId,
      userName,
      useBack
    } = this.$route.query;
    if (token && projectId) {
      this.$store.commit('user/SET_TOKEN', token);
      this.$store.commit('user/SET_USER_INFO', {
        projectId,
        userId,
        userName
      });
      this.$store.commit('user/SET_PROJECT_INFO', {
        projectId
      });
    }
    if (useBack) {
      this.useBack = true;
    }
    this.getDetail();
  },
  mounted() {},
  methods: {
    getDetail() {
      this.$store.dispatch('base/SetLoading', true);
      getDetailById({
        id: this.$route.query.id
      }).then(res => {
        const cb = res.data;
        if (cb.code === 200) {
          console.log(cb.data, '详情数据');
          this.form = cb.data;
        }
      }).finally(() => {
        this.$store.dispatch('base/SetLoading', false);
      });
    },
    onPayment() {
      const {
        id
      } = this.form;
      this.$router.push({
        name: 'CleanPayment',
        query: {
          id
        }
      });
    },
    onSubmit() {
      confirmComplete({
        id: this.$route.query.id
      }).then(res => {
        const cb = res.data;
        if (cb.code === 200) {
          this.$toast('处理完成');
          if (this.useBack) {
            if (isIOS() === 'ios') {
              window.webkit.messageHandlers.backPage.postMessage(null);
            } else {
              if (window.commonJS) {
                window.commonJS.backPage('');
              }
            }
          } else {
            this.goBack();
          }
        }
      });
    },
    // 复制
    docopy(text) {
      var input = document.createElement('input');
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功');
    },
    // 图片预览
    reviewImg(imgList) {
      const result = imgList.map(item => {
        return item.url;
      });
      ImagePreview(result);
    }
  },
  filters: {}
  //
};