var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-driections-navbar"
        }, [_c('van-form', {
          on: {
            "submit": _vm.onSubmit
          }
        }, [_c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "工单编号",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function () {
              return [_c('span', {
                staticClass: "detail-id"
              }, [_vm._v(_vm._s(_vm.form.id))]), _c('span', {
                staticStyle: {
                  "color": "#5E79EB"
                },
                on: {
                  "click": function ($event) {
                    return _vm.docopy(_vm.form.id);
                  }
                }
              }, [_vm._v("复制")])];
            },
            proxy: true
          }])
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "空间",
            "input-align": "right"
          },
          model: {
            value: _vm.form.spaceName,
            callback: function ($$v) {
              _vm.$set(_vm.form, "spaceName", $$v);
            },
            expression: "form.spaceName"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "问题类型",
            "input-align": "right"
          },
          model: {
            value: _vm.form.questionCategoryName,
            callback: function ($$v) {
              _vm.$set(_vm.form, "questionCategoryName", $$v);
            },
            expression: "form.questionCategoryName"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "图片",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function () {
              return _vm._l(_vm.form.questionDesImgList, function (item, index) {
                return _c('img', {
                  key: index,
                  staticClass: "image",
                  attrs: {
                    "src": item.fileUrl,
                    "alt": ""
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.reviewImg(_vm.form.questionDesImgList);
                    }
                  }
                });
              });
            },
            proxy: true
          }])
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "期望上门日期",
            "input-align": "right"
          },
          model: {
            value: _vm.form.expectRepairDate,
            callback: function ($$v) {
              _vm.$set(_vm.form, "expectRepairDate", $$v);
            },
            expression: "form.expectRepairDate"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "联系人",
            "input-align": "right"
          },
          model: {
            value: _vm.form.contactsName,
            callback: function ($$v) {
              _vm.$set(_vm.form, "contactsName", $$v);
            },
            expression: "form.contactsName"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "联系方式",
            "input-align": "right"
          },
          model: {
            value: _vm.form.contactsMobile,
            callback: function ($$v) {
              _vm.$set(_vm.form, "contactsMobile", $$v);
            },
            expression: "form.contactsMobile"
          }
        }), _vm.form.contactsRemark ? _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "rows": "3",
            "autosize": "",
            "maxlength": "100",
            "type": "textarea",
            "label": "备注",
            "input-align": "right"
          },
          model: {
            value: _vm.form.contactsRemark,
            callback: function ($$v) {
              _vm.$set(_vm.form, "contactsRemark", $$v);
            },
            expression: "form.contactsRemark"
          }
        }) : _vm._e(), _vm.form.amountYingShou ? _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "订单金额",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "button",
            fn: function () {
              return [_vm._v(" 元 ")];
            },
            proxy: true
          }], null, false, 409916136),
          model: {
            value: _vm.form.amountYingShou,
            callback: function ($$v) {
              _vm.$set(_vm.form, "amountYingShou", $$v);
            },
            expression: "form.amountYingShou"
          }
        }) : _vm._e(), _vm.form.status != 4 ? _c('div', {
          staticStyle: {
            "margin": "16px"
          }
        }, [_vm.form.payStatus === 0 ? _c('van-button', {
          attrs: {
            "round": "",
            "block": "",
            "type": "info",
            "color": "#D8B084",
            "native-type": "submit"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return _vm.onPayment.apply(null, arguments);
            }
          }
        }, [_vm._v("发起线上收款")]) : _c('van-button', {
          attrs: {
            "round": "",
            "block": "",
            "type": "info",
            "color": "#D8B084",
            "native-type": "submit"
          }
        }, [_vm._v("确认完成")])], 1) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };